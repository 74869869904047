import { useContext, useState } from "react";

import { Form, useNavigation } from "@remix-run/react";

import { LoadingButton } from "@mui/lab";
import {
  OutlinedInput,
  InputAdornment,
  Box,
  Stack,
  Typography,
} from "@mui/material";

import { faLocationDot } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IntersectionContext } from "~/components/Header/useIntersection";

import useAddressPredictions from "../Formulaire/useAddressPredictions";

export const AutoCompleteInput: React.FC<{
  url: string;
  ctaLabel: string;
  ctaColor: string;
  children?: React.ReactNode;
}> = ({ url, ctaLabel, ctaColor, children }) => {
  const intersection = useContext(IntersectionContext);
  const navigation = useNavigation();
  const [input, setInput] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const predictions = useAddressPredictions(input);
  const open = Boolean(anchorEl && input != "" && predictions.length);

  const handleClose = (description?: string) => {
    if (description) {
      (document.querySelector("#formatted_address") as HTMLInputElement).value =
        description;
    }
    setAnchorEl(null);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setAnchorEl(e.currentTarget);
    setInput(e.target.value);
  };

  return (
    <Form method="post" action={url} ref={intersection?.ref}>
      {children}
      <Box
        position="fixed"
        display={open ? "initial" : "none"}
        top="0"
        left="0"
        height="100vh"
        width="100vw"
        zIndex={1}
        onClick={() => handleClose()}
      />
      <OutlinedInput
        sx={(theme) => ({
          zIndex: 2,
          borderRadius: theme.shape.borderRadius * 0.75,
          width: "100%",
          backgroundColor: "common.white",
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "0 !important;",
          },
          pr: 1,
          [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexWrap: "wrap",
            pr: 0,
          },
        })}
        name="formatted_address"
        id="formatted_address"
        onChange={handleChange}
        placeholder="Entrez votre adresse..."
        endAdornment={
          <InputAdornment
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                mt: 2,
                mx: { xs: 4, sm: 2 },
              },
            })}
            position="end"
          >
            <LoadingButton
              variant="contained"
              size="large"
              type="submit"
              loading={navigation.state === "submitting"}
              sx={(theme) => ({
                width: { xs: "100%", sm: "auto" },
                borderRadius: theme.shape.borderRadius * 0.75,
                bgcolor: `${ctaColor}.main`,
                color: "white",
                ":hover": {
                  bgcolor: `${ctaColor}.dark`,
                  color: "white",
                },
              })}
            >
              {ctaLabel}
            </LoadingButton>
          </InputAdornment>
        }
      />
      <Box id="predicted-places" />
      <Box position="relative" top={0} left={0} width="100%">
        <Stack
          display={open ? "flex" : "none"}
          flexDirection="column"
          position="absolute"
          width="100%"
          zIndex={12}
          bgcolor="white"
          border={1}
          borderColor={(theme) => theme.palette.grey[300]}
          borderRadius={1}
        >
          {predictions.map(({ description }, index) => (
            <Stack
              flexDirection="row"
              key={index}
              onClick={() => handleClose(description)}
              borderBottom={index < predictions.length ? 1 : 0}
              borderColor="grey.200"
              p={0.5}
              sx={{
                cursor: "pointer",
                ":hover": {
                  backgroundColor: "grey.100",
                },
              }}
              alignItems="center"
            >
              <Box component={FontAwesomeIcon} icon={faLocationDot} mr={1} />
              <Typography>{description}</Typography>
            </Stack>
          ))}
        </Stack>
      </Box>
    </Form>
  );
};
